import { useLink, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import Head from "next/head";
import { FC } from "react";
import { useLayout } from "xcore";
import { File } from "@appiodev/xcore-core";
import { useRouter } from "next/router";

interface Props {
  title?: string | string[];
  metaTitle?: string | string[];
  description?: string;
  url?: string;
  img?: string | File;
}

const RobeHead: FC<Props> = ({ title: titleSpecified, metaTitle, description: _description, url, img, children }) => {
  const { locale } = useRouter();
  const [content, { domain, config, stringify, title: getTitle, file }] = usePageContent();
  const { general, contentTypes } = useLayout();
  const { as } = useLink();

  const title = titleSpecified ?? getTitle(content);
  const titleResolved = resolveTitle(title);
  const metaTitleResolved = resolveTitle(metaTitle ?? title);

  const description = _description ?? stringify(general.values.metaDescription);

  return (
    <Head>
      <title key="title">{titleResolved}</title>

      <meta key="og_title" property="og:title" content={metaTitleResolved} />
      <meta key="meta_title" name="title" content={metaTitleResolved} />

      <meta key="og_description" property="og:description" content={description} />
      <meta key="description" name="description" content={description} />

      {contentTypes[content.type]?.routes?.[locale ?? "en"] && (
        <meta
          key="og_url"
          property="og:url"
          content={url ?? as(content, { domain })}
        />
      )}

      <meta
        key="og_image"
        property="og:image"
        content={(typeof img === "string" ? img : file(img)) ?? "/static/og-image@2x.png"}
      />

      <meta
        key="og_locale"
        property="og:locale"
        content={locale}
      />

      {config.domains && Object.entries(config.locales).map(([l], i) =>
        contentTypes[content.type]?.routes?.[l] &&
          <link key={i} rel="alternate" href={as(content, { domain: config.domains![l] })} hrefLang={l}></link>
      )}

      {children}
    </Head>
  );
};

export default RobeHead;

const resolveTitle = (title: string | string[]) => {
  return typeof title === "string" ? title : title.filter(Boolean).join(" | ");
};

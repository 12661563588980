import { FC, ReactNode, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Flex, Container, Stack, Button, Link as XLink } from "@xcorejs/ui";
import { useLayout } from "xcore";
import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Content } from "@appiodev/xcore-core";
import { AppCookies } from "utils/cookies";

interface Props {
  agreeBtn: ReactNode;
  moreBtn: ReactNode;
  link: Content;
}

const CookieBar: FC<Props> = ({ children, agreeBtn, moreBtn, link }) => {
  const { cookies, stringify } = useLayout();
  const [localState, setConsent] = useState(false);

  useEffect(() => {
    setConsent(Cookies.get(AppCookies.cookieConsent) === "1" || Cookies.get(AppCookies.cookieConsent) === "2");
  }, []);

  if (localState) return null;

  return (
    <Flex
      alignItems="center"
      position="fixed"
      bottom={0}
      width="100%"
      bg="lightGray"
      zIndex={1001}
    >
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          padding="1rem 0"
          flexDirection="column"
        >
          {children}

          <Flex justifyContent="space-between" width="100%" alignItems="center" flexDirection={{ _: "column", md: "row" }} mt="1rem">
            <Stack gap="3rem" alignItems="center">
              <Button
                flex="0 0 auto"
                size="sm"
                onClick={() => {
                  Cookies.set(AppCookies.cookieConsent, "1", { expires: 365 * 2 });
                  setConsent(true);
                }}
              >
                {agreeBtn}
              </Button>

              {link && <Link content={link} flex="0 0 auto">{moreBtn}</Link>}
            </Stack>

            <XLink
              flex="0 0 auto"
              color="gray"
              onClick={() => {
                Cookies.set(AppCookies.cookieConsent, "2", { expires: 1 });
                setConsent(true);
              }}
              textAlign="center"
              mt={{ _: "1rem", sm: 0 }}
            >
              {stringify(cookies.values.btnDecline)}
            </XLink>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default CookieBar;
